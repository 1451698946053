import { Link } from "react-router-dom"
import Button from "../../components/Button"
import Header from "../../components/Header"
import Icon from "../../components/Icon"
import { Trans, useTranslation } from "react-i18next"
import { useEffect, useMemo, useState } from "react"
import useAuth from "../../hooks/useAuth"
import { useDetailFromID } from "../../hooks/useDetailFromID"
import ARView from "../../components/ARView"
import AuthModal from "../../components/AuthModal"

export default function UnboxSuccess({itemId}: {
  itemId: string
}) {
  const {data: item} = useDetailFromID(itemId)

  const {phoneNumber, isLoggedIn} = useAuth()
  const {t} = useTranslation()

  const [mode, setMode] = useState('view')
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [waitForToken, setWaitForToken] = useState(false)

  const rarity = useMemo(() => {
    return item.nftMetadata?.rarity || '1'
  }, [item])

  const handleShowAR = () => {
    if (isLoggedIn) {
      setMode('ar')
      return
    }
    setShowAuthModal(true)
    setWaitForToken(true)
  }

  useEffect(() => {
    if (waitForToken && isLoggedIn) {
      setMode('ar')
      setWaitForToken(false)
    }
  }, [waitForToken, isLoggedIn])

  if (mode === "ar") {
    return (
      <ARView item={item} onClose={() => setMode("view")} />
    )
  }

  return (
    <>
      <div className="bg-[#F1EEEB]">
        <Header />
        <div className="p-[28px] flex flex-col items-center">
          {/* {item.nftMetadata?.avatar && (
            <div className="flex w-full justify-center relative">
              <div className="flex justify-center absolute top-0">
                <span className="text-[#000] font-paytone leading-[73.75px] text-[59px] text-center uppercase">
                  {item.collectionMetadata?.name}
                </span>
              </div>
            </div>
          )} */}
          {item.nftMetadata?.avatar && (
            <img
              src={item.nftMetadata.avatar}
              className="z-10 relative w-[213px] h-[264px] mt-[32px]"
              alt={`${item.collectionMetadata?.name || ''} ${item.nftID}`}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col items-center py-[12px] px-[38px]">
        <span className="text-[#000] font-paytone leading-[25px] text-[20px] text-center uppercase">
          {item.collectionMetadata?.name}
        </span>
        <div className="flex justify-center bg-[#000] p-[6px] my-[2px]">
					<span className="text-[#FFF] font-paytone leading-[20px] text-[16px] text-center uppercase">
						{/* {t('digitalVersion')} */}
            {item.nftMetadata?.rarityCode}
					</span>
				</div>
        <span className="text-center pt-[24px] pb-[48px]">
          {phoneNumber === item.nftMetadata?.owner ? (
            <>
              {t('rarityLegendaryDesc', {characterName: item.collectionMetadata?.name})}
              {' '}{'⎯'}{' '}
              <Trans
                i18nKey={"rarityLegendaryRarity"}
                values={{rarity}}
              >
                Chúc mừng bạn trong nhóm <strong>{rarity}%</strong> khách hàng may mắn sở hữu.
              </Trans>
            </>
          ): (
            `Chủ sở hữu: ${item.nftMetadata?.owner}`
          )}
        </span>
        <Link to={`/profile/${phoneNumber}`} className="w-full">
          <Button
            className="w-full mb-[28px]"
          >
            {t('goToProfile')}
          </Button>
        </Link>
        {(!isLoggedIn || (isLoggedIn && phoneNumber === item.nftMetadata?.owner)) && (
          <div className="flex flex-col items-center gap-2">
            <Icon
              name="ar"
              width={32}
              height={32}
            />
            <button className="flex gap-1" onClick={handleShowAR}>
              <span className="text-[14px] font-bold leading-[18px]">
                {t('interactAR')}
              </span>
              <Icon
                name="arrow-right"
                width={16}
                height={16}
              />
            </button>
          </div>
        )}
        <AuthModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          onSuccess={() => {
            setShowAuthModal(false)
          }}
        />
      </div>
    </>
  )
}