import React, { useEffect } from 'react';
import './App.css';
import "./i18n"
import { MOBILE_WIDTH,GA_TRACKING_ID } from './config/constant';
import UnsupportedDevices from './pages/UnsupportedDevices';
import { useWindowSize } from 'usehooks-ts';
import { RouterProvider, createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import HomePage from './pages/Home';
import UnboxPage from './pages/Unbox';
import { useTranslation } from 'react-i18next';
import { usePreferenceStore } from './state/preference';
import ItemDetailPage from './pages/ItemDetail';
import SendGiftPage from './pages/SendGift';
import ProfileCollectionPage from "./pages/ProfileCollection";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import EnterPage from './pages/Enter';
import ReactGA from "react-ga4";
import useAuth from './hooks/useAuth';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f4e2b11b390eb7dd7f004d30c394977f@o495346.ingest.sentry.io/4506748926754816",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/backend\.sutaso\.com/],
  // tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient()

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/unbox",
    element: <UnboxPage />
  },
  {
    path: "/item/:itemId",
    element: <ItemDetailPage />
  },
  {
    path: "/send-gift/:itemId",
    element: <SendGiftPage />
  },
  {
    path: "/enter",
    element: <EnterPage />
  },
  {
    path: "/profile/:phoneNumber",
    element: <ProfileCollectionPage />
  }
]);

ReactGA.initialize(GA_TRACKING_ID);

function App() {
  const { width } = useWindowSize()
  const { i18n } = useTranslation()
  const { language } = usePreferenceStore()
  const { expiredAt, fetchNewToken, refreshToken } = useAuth()

  useEffect(() => {
    if (expiredAt < Date.now() && refreshToken) {
      fetchNewToken()
    }
  }, [expiredAt, fetchNewToken, refreshToken])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language, i18n])

  if (width > MOBILE_WIDTH) {
    return <UnsupportedDevices />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
