import { COLLECTION_DETAIL_BY_CODE_PATH, COLLECTION_DETAIL_PATH, PORTFOLIO_PATH } from "../config/path";

export const getCollectionDetail = async (collectionID: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${COLLECTION_DETAIL_PATH}${collectionID}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getCollectionDetail error', error)
    return 
  }
}

export const getCollectionDetailByCode = async (code: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${COLLECTION_DETAIL_BY_CODE_PATH}${encodeURIComponent(code)}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getCollectionDetailByCode error', error)
    return 
  }
}

export const getPortfolio = async (phoneNumber: string, page: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${PORTFOLIO_PATH}?phone=${phoneNumber}&page=${page}&limit=10`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getCollectionDetailByCode error', error)
    return 
  }
}