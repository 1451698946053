export const isPhoneNumber = (val: string) => {
  return /(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(val)
}

export const addZero = (val: string) => {
  if (val[0] === "0") return val
  return `0${val}`
}

export const hidePhone = (val: string) => {
  return `${val.substring(0, 3)}***${val.substring(val.length - 3)}`
}